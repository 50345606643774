@use "variables" as *;
@use "helpers" as *;
@use "typography" as *;
@use "placeholders/forms" as *;
@use "mixins" as *;

.btn {
  @extend %button-height;

  white-space: nowrap;
  font-size: $font-size-1;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  border-width: 0.1rem;

  &:hover {
    text-decoration: underline;
  }

  &:not(:disabled):hover {
    text-decoration: underline;
  }

  &.btn-compact {
    padding: 0.8rem;
  }

  &.btn-icon-round {
    padding: 0.2rem;
    height: 2.4rem;
    border-radius: 5rem;
  }

  &.btn-icon-square {
    @extend %button-square-size;

    padding: 0;
  }
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.mc-btn-danger {
  @include button-variant(
    $color-verisk-behavioural-red,
    $color-verisk-behavioural-red,
    $color-verisk-behavioural-red,
    $color-verisk-behavioural-red,
    $color-verisk-behavioural-red,
    $color-verisk-behavioural-red
  );

  background-color: $color-verisk-behavioural-red;
  border-radius: 0.4rem;
  font-weight: $font-weight-medium;
  font-size: $font-size-2;
  color: $color-verisk-white;

  &:hover,
  &:focus {
    color: $color-verisk-white;
  }

  &.disabled,
  &:disabled {
    color: $color-verisk-black-20;
    background-color: $color-verisk-black-5;
    border-color: $color-verisk-black-5;
    pointer-events: none;

    &:focus {
      box-shadow: none;
      color: $color-verisk-black-20;
      background-color: $color-verisk-black-5;
      border-color: $color-verisk-black-5;
    }

    &:hover {
      filter: unset;
      text-decoration: none;
    }
  }

}

.mc-btn-primary {
  @include button-variant(
    $color-verisk-action-blue,
    $color-verisk-action-blue,
    $color-verisk-action-blue,
    $color-verisk-action-blue,
    $color-verisk-action-blue,
    $color-verisk-action-blue
  );

  box-shadow: none;
  border-style: solid;
  border-radius: 0.4rem;
  font-weight: $font-weight-medium;
  font-size: $font-size-2;

  &:not(.error):not(.disabled):not(:disabled):hover,
  &:not(.error):not(.disabled):not(:disabled):focus {
    color: $color-verisk-white;
    filter: brightness(90%);
    background-color: $color-verisk-action-blue;
    box-shadow: 0 0 0 0.1rem $color-verisk-action-blue;
  }

  &.error {
    &:not(:disabled):not(.disabled):active {
      background-color: $color-verisk-behavioural-red;
      border-color: $color-verisk-behavioural-red;
      box-shadow: 0 0 0 0.1rem $color-verisk-behavioural-red;
      border: 0.2rem solid $color-verisk-behavioural-red !important;
    }
  }

  &.disabled,
  &:disabled {
    color: $color-verisk-black-20;
    background-color: $color-verisk-black-5;
    border-color: $color-verisk-black-5;
    pointer-events: none;

    &:focus {
      box-shadow: none;
      color: $color-verisk-black-20;
      background-color: $color-verisk-black-5;
      border-color: $color-verisk-black-5;
    }

    &:hover {
      filter: unset;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color-white;
  }
}

.mc-btn-secondary {
  @include button-variant(
    $color-verisk-white,
    $color-verisk-black-20,
    $color-verisk-white,
    $color-verisk-black-20,
    $color-verisk-white,
    $color-verisk-globe-blue
  );

  box-shadow: none;
  border-style: solid;
  border-radius: 0.4rem;
  font-weight: $font-weight-medium;
  font-size: $font-size-2;
  color: $color-verisk-action-blue;

  &:hover {
    color: $color-verisk-action-blue;
    text-decoration: underline;
    border-color: $color-verisk-action-blue;
  }

  &:focus {
    box-shadow: none;
    color: $color-verisk-action-blue;
    background-color: $color-verisk-white;
    border-color: $color-verisk-black-20;
  }

  &:not(:disabled):hover {
    color: $color-verisk-action-blue;
    text-decoration: underline;
    border-color: $color-verisk-action-blue;
    background-color: $color-verisk-white;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color-verisk-action-blue;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: none;
  }
}

.btn-outline-maplecroft-secondary {
  @include button-variant(#fff, #ccc, #f1f1f1, #ccc, #fff, #ccc);

  color: $color-label-grey;
  box-shadow: none;
  border-style: solid;
  border-radius: 4px;

  &:hover {
    color: $color-label-grey;
  }

  &.disabled,
  &:disabled {
    color: $color-maplecroft-bright-grey;
    background-color: #fafafa;
    border-color: $color-maplecroft-bright-grey;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color-label-grey;
  }
}

.btn-maplecroft-dropdown {
  padding: $padding-1;
  display: inline-block;
  text-transform: uppercase;
  background: #00a9e0;
  border: 1px solid #00a9e0;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  transition: background 0.1s ease-in;
}

.btn-link.btn-anchor {
  padding: 0;
  border: 0;
  vertical-align: baseline;
  color: $color-verisk-action-blue-10;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.tag {
  font-size: 12px;
  display: inline-block;
  background-color: #f3f3f1;
  border: 1px solid #dddddc;
  border-radius: 2px;
  margin: 0 5px 5px 0;
  padding: 0;
  transition: all 0.2s ease-out;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto !important;
  float: left;
}

.tag:hover {
  background-color: #00a9e0;
  border: 1px solid #00a9e0;
}

.tag:hover a {
  color: #fff;
}

.modify-serach {
  color: #000;
  display: block;
  padding: 5px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modify-search span {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag.selected:hover {
  background-color: #f3f3f1;
  border: 1px solid #dddddc;
}

.tag.selected:hover a {
  color: #000;
}

.tag.lead-tag {
  background-color: #22b8ef;
  border-color: #22b8ef;
  background: #7dcbe8;
  background: linear-gradient(to bottom, #7dcbe8 0%, #22b8ef 100%);
}

.tag.lead-tag a span {
  color: #fff;
}

.tabs {
  display: table;
  position: relative;
  padding: 0;
  border-bottom: 0;
  margin: 0 0 0 1px;
  width: 100%;
}

.tabs li {
  float: left;
  background: #f3f3f3;
  border: 1px solid #ccc;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  margin: 0 0 0 -1px;
  display: inline-block;
  width: 50%;
}

.tabs li:first-child {
  right: -1px;
}

.tabs li a {
  height: 100%;
  width: 100%;
  border-radius: 0;
  border: 0;
  float: left;
  color: #999;
  white-space: nowrap;
  padding: 8px 10px;
  margin: 0;
}

.tabs li a:hover {
  background-color: #eee;
}

.tabs li.active {
  border-bottom: 0;
  background: #fff;
  color: #000;
}

.tabs li.active a {
  color: #000;
}

.tabs li.active a:hover {
  background-color: #fff;
}

.mc-toggle {
  &.active {
    @extend .mc-btn-primary;
  }

  &:not(.active) {
    @extend .mc-btn-secondary;

    border-color: $color-verisk-black-20;

    &:hover {
      border-color: $color-verisk-action-blue;
    }
  }

  &.toggle-compact {
    padding: 0.8rem;
    height: 2.4rem;
    line-height: 0.8rem;
    font-size: $font-size-1;
  }
}
